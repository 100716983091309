import React from 'react'

import Layout from "../../../components/Layout"
import CaseStudyStep from '../../../components/CaseStudyStep'

export default class DiagnosticStepSix extends React.Component {

  render() {
    return (
      <div>
        <Layout>
          <CaseStudyStep
            title="Step #6"
            question="How will you monitor the success of this patient’s medication? What screening and preventive measures would you take to minimize risks to her physical and mental health?"
            body={[
              <h3>Expert Opinion</h3>,            
              <p>It is essential for primary care doctors to realize that their patients are taking this medication class and understand how it may affect their overall health and primary care needs. Here, as with any patient taking a new medication, adequate medication reconciliation is a must.  Medication reconciliation is the act of revisiting a patient’s medication history, ensuring the accuracy of the active list and taking it into account when prescribing new medications and preparing a treatment plan. For patients on biologics this can be a bit tricky given that these medications are not taken daily, and patients may thus forget to include them when asked about active medications. Of course, documentation of the biologic treatment plan should be clearly included in all progress notes forwarded by the gastroenterologist to all members of the care team (including the primary care doctor).</p>,
              <p>It is also important for the primary care provider to recognize ways in which Jennifer’s disease and current medication put her at risk for other health conditions, and to take appropriate preventive and screening measures. For example, the effect of chronic inflammation on the bones, low serum vitamin D levels, and prior corticosteroid use all put Jennifer, like other IBD patients, at an increased risk of developing osteoporosis. The basics of good osteoporosis prevention include sufficient calcium intake (ideally through diet), vitamin D levels (with supplements as necessary if low blood levels continue), muscle strengthening exercises to reduce fall risk, and medications as necessary for primary prevention. DEXA scans are the standard of care for diagnosis of osteoporosis.</p>,
              <p>Patients with Crohn’s disease are also at increased risk for colon cancer if the disease affects more than a third of the colon. Since Jennifer’s disease does not involve her colon, she is currently not at an increased risk. Cancers that do happen more frequently in Crohn’s patients are non-melanoma skin cancers, lymphoma, and small bowel adenocarcinoma (though these are still rare in terms of absolute risk). </p>,
              <p>As Jennifer’s PCP, you will also want to recognize that patients who have any chronic disease are at increased risk of depression as well as anxiety. Incidence of depression as high as 25% have been seen in various studies of patients with Crohn’s data sets. Rates of depression or anxiety can exceed 30% of patients. The rates correlate with Crohn’s disease activity as defined by indices that include clinically relevant variables without endoscopy. . Patients who have uncontrolled depression may have worse outcomes given the higher rate of non-adherence to medical therapy.  Additionally, depression has a greater impact on the quality of life than underlying Crohn’s disease activity. For all of these reasons, and the high incidence of depression in the general population, it is important to have a screening mechanism set up in any primary care practitioner’s office. While there are several validated instruments that can be used in a primary care setting, the PHQ-2 (Patient Health Questionnaire-2) is the most widely used given its sensitivity, specificity, and brevity. </p>,
              <p>There are data to suggest that women on chronic immunomodulators are at increased risk for HPV infection and subsequent progression to cervical dysplasia. The current recommendation is that women on thiopurines should have annual Pap smears. The risk of cervical dysplasia does not appear to be increased in those on biologics. This is likely due to the mechanism by which HPV causes DNA changes and dysplastic transformation. </p>,
              <p>Jennifer’s question about another baby is a good opportunity to talk with her about the basics of managing Crohn’s during pregnancy. While pregnancy can sometimes improve symptoms in some patients, ideally conception should occur when Crohn’s symptoms are well controlled.  Jennifer should stay on her biologic while trying to conceive to control disease activity, and because biologics do not cross the placenta until after week 20, she can use it up to that point with no known risk to the mother or fetus. Certolizumab is the one exception: because it does not cross the placenta at all given its chemical structure, it may be the preferred therapy in women of childbearing age who are actively considering conception and pregnancy. However, it is not advisable to change a woman’s therapy over to certolizumab if she is already pregnant, as there is no guarantee that it will work as well as her original agent.  If Jennifer were taking certain other medications, they would need to be suspended and replaced.  Methotrexate, for example, can be teratogenic for the fetus and needs to be stopped three months before trying to conceive.  And in a male patient, sulfasalazine may affect sperm count.</p>,
              <p>Finally, it is important for the primary care practitioner to understand the role that the disease itself and the medications used to treat it (particularly immunosuppressants) play in ensuring appropriate vaccination. Jennifer, like many patients with Crohn’s disease is at increased risk for certain infections due to an altered and suppressed immune system. In particular, because Jennifer is on a biologic agent, she should not have live-virus vaccines administered. The Infectious Disease Society of America categorizes the degree of immunosuppression as low for patients on short courses of low-dose prednisone, methotrexate (&lt; 04.mg.kg/week), azathioprine (&lt; 3.0 mg/kg/day) or mercaptopurine (&lt; 1.5mg/kg/day). Patients on higher doses and those on biologics are considered to be at high risk. </p>,
              <p>Jennifer should, however, receive an influenza vaccine annually. To prevent pneumococcal disease, she should also receive pneumococcal conjugate vaccine (PCV 13) followed by pneumococcal polysaccharide vaccine (PPSV 23) at 8 weeks due to her high degree of immunosuppression (the interval should be 1 year for all others). If Jennifer is new to your practice and unable to provide records of previous vaccinations, it may be necessary to do antibody titer testing to ensure appropriate vaccine status for hepatitis as well. She should be given a hepatitis A vaccine at 0 and 6 months if no antibodies are detected on titer, and hepatitis B at 0, 1 and 6 months with additional boosters provided as necessary. She should be offered TdAP at least once and provided Td every ten years. Patients between 11 and 26 years should be vaccinated against HPV, and ACIP (Advisory Committee on Immunization Practices) recommendations for the general public should be followed for meningococcal vaccine, although these recommendations are not relevant to Jennifer, who is in her 30’s.</p>,
              <p>Given the complexity of vaccine requirements and schedules, you will also want to clarify respective roles and responsibilities with Jennifer’s gastroenterology practitioner. It is wise to coordinate vaccination as early in the disease course as possible given immunity and antibody formation following vaccination wanes over time. Having office-based checklists/flowsheets for patients with Crohn’s can ensure that vaccinations are performed as well.  Educating patients about the requirements can create willing partners who can also advocate for vaccinations. Primary care practitioners should have a good grasp of vaccine-related mythology and beliefs based on non-scientific rationale so that they can address these in a patient-centered way to encourage patients appropriately.</p>

            ]}
            slideLink="#"
            cmeLink="/cme"
            backLink="/cases/health-maintenance-primary-care#part-3"
            backText="Back to Case Three"
            nextLink="/cases/conclusion"
            nextText="Go to Conclusion"
          />
        </Layout>
      </div>
    )
  }
}
